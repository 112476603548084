import React, { createContext, useState, useEffect, useContext } from "react";
import client from "../api/client";

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [authTokens, setAuthTokens] = useState(() => {
    const storedTokens = localStorage.getItem("authTokens");
    return storedTokens ? JSON.parse(storedTokens) : null;
  });

  const [userInfo, setUserInfo] = useState(() => {
    const storedUserInfo = localStorage.getItem("userInfo");
    return storedUserInfo ? JSON.parse(storedUserInfo) : null;
  });

  const [loading, setLoading] = useState(true);
  const [registerLoading, setRegisterLoading] = useState(false);
  const [loginLoading, setLoginLoading] = useState(false);
  const [isRedirectedToMall, setIsRedirectedToMall] = useState(false);
  const [isFirstTime, setIsFirstTime] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    if (authTokens) {
      client.setJwt(authTokens.access);
    }
    setLoading(false);
  }, [authTokens]);

  const login = async (username, password) => {
    setLoginLoading(true);
    try {
      const response = await client.post("/auth/jwt/create/", {
        username,
        password,
      });
      const data = response.data;
      setAuthTokens({ access: data.access, refresh: data.refresh });
      setUserInfo({
        username: data.username,
        email: data.email,
        isAdmin: data.isAdmin,
      });
      localStorage.setItem(
        "authTokens",
        JSON.stringify({ access: data.access, refresh: data.refresh })
      );
      localStorage.setItem(
        "userInfo",
        JSON.stringify({
          username: data.username,
          email: data.email,
          isAdmin: data.isAdmin,
        })
      );
      setError("");
      setIsRedirectedToMall(true);
      console.log("login success", data);
      return { success: true, data };
    } catch (error) {
      setError({ 
        login: error.response?.data || "An error occurred during login", 
        network: "There was an error logging in, could be a network issue." 
      });
      console.log("error logging in", error);
      return { success: false, error: error.response?.data || error.message };
    } finally {
      setLoginLoading(false);
    }
  };

  const register = async (username, email, password, re_password) => {
    setRegisterLoading(true);
    try {
      const response = await client.post("/auth/users/", {
        username,
        email,
        password,
        re_password
      });
      console.log("response", response);
      setIsFirstTime(true);
      setError("");
      return { success: true, data: response.data };
    } catch (error) {
      setError({ 
        register: error.response?.data || "An error occurred during registration", 
        network: "There was an error registering user, could be a network issue" 
      });
      console.log("error registering user", error.response?.data);
      return { success: false, error: error.response?.data || error.message };
    } finally {
      setRegisterLoading(false);
    }
  };

  const logout = () => {
    setAuthTokens(null);
    setUserInfo(null);
    localStorage.removeItem("authTokens");
    localStorage.removeItem("userInfo");
    client.setJwt(null);
    setIsRedirectedToMall(false);
    setIsFirstTime(false);
  };

  const updateProfile = async (username, email, password) => {
    try {
      let payload = {};
      if (username !== userInfo.username && username !== "") {
        payload.username = username;
      }
      if (email !== userInfo.email && email !== "") {
        payload.email = email;
      }
      if (password !== "") {
        payload.password = password;
      }
      if (Object.keys(payload).length === 0) return { success: true };

      const response = await client.patch("/auth/users/me/", payload);
      const data = response.data;
      setUserInfo({
        username: data.username,
        email: data.email,
        isAdmin: userInfo.isAdmin,
      });
      localStorage.setItem(
        "userInfo",
        JSON.stringify({
          username: data.username,
          email: data.email,
          isAdmin: userInfo.isAdmin,
        })
      );
      setError("");
      return { success: true, data };
    } catch (error) {
      return { success: false, error: error.response?.data || error.message };
    }
  };

  const contextData = {
    authTokens,
    userInfo,
    error,
    setError,
    loading,
    login,
    register,
    logout,
    updateProfile,
    registerLoading,
    loginLoading,
    isRedirectedToMall,
    isFirstTime,
  };

  return (
    <AuthContext.Provider value={contextData}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);

export default AuthProvider;