import React, { useState, useEffect } from 'react'
import * as Yup from 'yup'
import { Link, useNavigate } from 'react-router-dom'

import { SubmitButton, FormField, Form, ErrorMessage } from './form'
import { useAuth } from '../context/AuthProvider'
import CircleLoading from './CircleLoading'

const validationSchema = Yup.object().shape({
    username: Yup.string().required("Please enter username or email").label("Username/Email"),
    password: Yup.string().required("Please insert password").min(8).label("Password").matches(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/, "Must contain at least one uppercase, one lowercase, one number and one symbol"),
})

const LoginForm = () => {
    const [passwordVisible, setPasswordVisible] = useState(false)
    const { login, error, setError, loginLoading, isRedirectedToMall } = useAuth()

    useEffect(() => {
        setError('')
    }, [])

    const navigate = useNavigate()
    const loginError = error?.login?.detail || error?.network

    const handleSubmit = ({username, password}) => {
        login(username.trim(), password)
    }

    useEffect(() => {
        if (isRedirectedToMall) {
            navigate('/mall')
        }
    }, [isRedirectedToMall])

    return (
        <div className="w-full pb-6 px-1 xs:px-2 sm:px-5 flex-center flex-col rounded-lg">
            <div className="h-10 text-center text-2xl w-full py-1">
                <h2 
                    className='text-gradient animate__animated animate__bounceInDown'   
                    style={{animationDelay: '0.05s'}}
                >Login Form</h2>
            </div>
            <div className="w-full ">
                <ErrorMessage error={loginError} visible={loginError} />
                <CircleLoading visible={loginLoading} />
                <Form
                    initialValues={{username: '', password: ''}}
                    onSubmit={handleSubmit}
                    validationSchema={validationSchema}
                >
                    <div 
                        className="w-full my-8 animate__animated animate__bounceInDown"
                        style={{animationDelay: '0.3s'}}
                    >
                        <FormField
                            icon="user"
                            placeholder="Username or Email"
                            label="Username/Email"
                            name="username"
                        />
                    </div>
                    <div 
                        className="w-full animate__animated animate__bounceInDown"
                        style={{animationDelay: '0.5s'}}
                    >
                        <FormField
                            icon={passwordVisible ? "eye-slash" : "eye"}
                            placeholder="Password"
                            label="Password"
                            type={passwordVisible ? "text" : "password"}
                            name="password"
                            onIconClick={() => setPasswordVisible(prev => !prev)}
                            ariaLabel={passwordVisible ? "Hide password" : "Show password"}
                        />
                    </div>
                    <div 
                        className="w-full py-4 flex-center animate__animated animate__bounceInDown"
                        style={{animationDelay: '0.7s'}}
                    >
                        <SubmitButton title="Login"/>
                    </div>
                    <div 
                        className="text-center p-2 rounded-md animate__animated animate__bounceInDown"
                        style={{animationDelay: '0.9s'}}
                    >
                        <Link to="/auth/forgot-password" className="text-[#33bbcf]">Forgot password?</Link>
                    </div>
                </Form>
            </div>
        </div>
    )
}

export default LoginForm