import React from 'react'
import { Link } from 'react-router-dom'

import Button from './Button'

const ProductCard = ({ linkTo, condition, img, name, originalPrice, discountedPrice, description, addToCartClick}) => {
  return (
    <Link 
      className="card p-2 rounded shadow border-gradient" 
      tabIndex="0" 
      to={linkTo} 
    >
      <div className="w-full relative" data-aos="fade-up" data-aos-delay="100">
        <div className="absolute bottom-2 right-2 rounded-[50%20%20%10%] p-2 bg-black-gradient text-cyan-200 text-sm">{condition}</div>
        <div className="w-full h-36 md:h-44 mb-2 border-gradient rounded-md p-1" >
          <img src={img} alt="" className='w-full h-full object-contain' />
        </div>
        <h3 className="font-bold text-white truncate">{name}</h3>
        <div className="flex items-center gap-2"> 
          <p className="text-gray-400 line-through text-sm sm:text-lg" aria-label='old price'>$ {originalPrice}</p>
          <p className="text-xl sm:text-2xl text-gradient" aria-label='new price'>$ {discountedPrice}</p>
        </div>
        <p className='truncate-3 text-white mb-2'>{description}</p>
        <Button 
          style={{
            padding: '6px 12px',
          }} 
          title="Add to cart"
          onClick={addToCartClick}
        />
      </div>
    </Link>
  )
}

export default ProductCard