import axios from 'axios';

const client = axios.create({
    baseURL: 'http://localhost:8000',
    headers: {
        'Content-Type': 'application/json',
    },
});

// Intercept requests to add the JWT token
client.interceptors.request.use(
    (config) => {
        const authTokens = JSON.parse(localStorage.getItem('authTokens'));
        if (authTokens && authTokens.access) {
            config.headers['Authorization'] = `JWT ${authTokens.access}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

// Intercept responses to handle token refresh
client.interceptors.response.use(
    (response) => response,
    async (error) => {
        const originalRequest = error.config;
        if (error.response?.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;
            try {
                const authTokens = JSON.parse(localStorage.getItem('authTokens'));
                const refreshResponse = await axios.post('/auth/jwt/refresh/', {
                    refresh: authTokens.refresh
                });
                const { access } = refreshResponse.data;
                localStorage.setItem('authTokens', JSON.stringify({ ...authTokens, access }));
                originalRequest.headers['Authorization'] = `JWT ${access}`;
                return client(originalRequest);
            } catch (refreshError) {
                // If refresh fails, logout the user
                localStorage.removeItem('authTokens');
                localStorage.removeItem('userInfo');
                // Optionally, redirect to login page or dispatch a logout action
                return Promise.reject(refreshError);
            }
        }
        return Promise.reject(error);
    }
);

client.setJwt = function(token) {
    if (token) {
        this.defaults.headers.common['Authorization'] = `JWT ${token}`;
    } else {
        delete this.defaults.headers.common['Authorization'];
    }
};

export default client;