import React from 'react'
import '../assets/animation/circleLoading.css'

const CircleLoading = ({size=50, visible}) => {
    if (!visible) return null
  return (
    <div className="flex-center">
        <div
            className="circle-loading"
            style={{
                width: size,
                height: size,
            }}
            role='alert'
        >
        <div className="sr-only">Loading...</div>
        <div className="bubble"></div>
        <div className="bubble"></div>
        <div className="bubble"></div>
        <div className="bubble"></div>
        <div className="bubble"></div>
        <div className="bubble"></div>
        </div>
    </div>
  )
}

export default CircleLoading