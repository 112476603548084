import React, {useState, useRef, useEffect} from 'react'
import { Link } from 'react-router-dom'
import 'animate.css'

// custom imports
import logo from '../assets/logo.webp'
import SearchButton from './SearchButton'
import SearchInput from './SearchInput'
import Button from './Button'
import { useCartItems } from '../context/CartItemsProvider'
import { useAuth } from '../context/AuthProvider'

const Navbar = () => {
  const [mobileNavToggle, setMobileNavToggle] = useState(false)
  const [fixedNav, setFixedNav] = useState(false)
  const [showSearchBar, setShowSearchBar] = useState(false)

  const mobileNavMenuRef = useRef(null)
  const { cartItems } = useCartItems()
  const { userInfo } = useAuth()

  const handleScrollNav = () => {
    if(window.scrollY > 100) {
      setFixedNav(true)
      setShowSearchBar(false)

      mobileNavMenuRef.current.classList.remove('mobile-nav-active')
      mobileNavMenuRef.current.classList.add('mobile-nav-inactive')

      setMobileNavToggle(false)
    } else {
      setFixedNav(false)
    }
  }

  // handle scroll event
  useEffect(() => {
    window.addEventListener('scroll', handleScrollNav);

    return () => {
      window.removeEventListener('scroll', handleScrollNav);
    };
  }, []);

  return (
    <nav role='navigation' className={`w-full flex py-2 justify-between items-center navbar z-[99999] duration-300 ${fixedNav ? 'nav-fixed' : ''}`}>
      <Link to="/" className='animate__animated animate__bounceInDown flex items-baseline gap-1'>
        <img src={logo} alt="Linkingh logo" className='w-[20px] xs:w-[30px] sm:w-[40px] md:w-[50px] drop-shadow ' />
        <span className='text-yellow-500 block text-xs sm:text-sm'>LINKINGH</span>
      </Link>
      {/* nav list */}
      <ul className='hidden sm:flex justify-end items-center flex-1 gap-4'>
        <li>
          <Link
            to='/' 
            className='text-white text-[1.1rem]'
          >home</Link>
        </li>
        <li>
          <Link 
            to='/services' 
            className='text-white text-[1.1rem]'
          >services</Link>
        </li>
        <li>
          <Link 
            to='/contact' 
            className='text-white text-[1.1rem]'
          >contact us</Link>
        </li>
        <li>
          <Link 
            className="bg-blue-gradient text-slate-800 w-[40px] h-[40px] rounded-full duration-300 hover:-translate-y-1 text-xl relative flex items-center justify-center flex-shrink-0"
            aria-label="shopping cart"
            to={userInfo ? '/cart' : '/auth'}
          >
            <i className="fas fa-shopping-cart"></i>
            <span className="sr-only">shopping cart</span>
            <h2 className='absolute -bottom-1 shadow-sm shadow-cyan-500 right-1 bg-black-gradient-2 px-[1px] py-[1px] h-5 w-5 flex-center text-white rounded-full text-xs'>{userInfo ? cartItems?.length : 0}</h2>
          </Link>
        </li>
        {!userInfo && <li>
            <Link 
              to='/auth'
              className='text-white text-[1.1rem]'
            >Login</Link>
        </li>}
        <li>
          <SearchButton 
            onClick={() => setShowSearchBar(prev => !prev)}
          />
        </li>
      </ul>
      {/* end of nav */}
      {/* mobile nav */}
      <button 
        className='sm:hidden flex flex-1 justify-end items-center relative' 
        onClick={() => setMobileNavToggle(prev => !prev)}
        aria-label={mobileNavToggle ? 'close mobile nav' : 'open mobile nav'}
      >
        {mobileNavToggle ? (
          <svg xmlns="http://www.w3.org/2000/svg" className="h-7 w-7 xs:h-10 xs:w-10 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor" >
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
          </svg>
        ) : (
          <svg xmlns="http://www.w3.org/2000/svg" className="h-7 w-7 xs:h-10 xs:w-10 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16m-7 6h7" />
          </svg>
        ) }
        
        <ul 
          className={`absolute right-0 top-[180%] p-4 bg-black-gradient rounded-lg w-[80vw] xs:w-[60vw] min-h-32 flex items-center justify-center flex-col gap-3 duration-500 ${mobileNavToggle ? 'mobile-nav-active' : 'mobile-nav-inactive'}`}
          ref={mobileNavMenuRef}
        >
          <li>
            <Link 
              to='/'
              className='text-white text-[1.1rem]'
            >home</Link>
          </li>
          <li>
            <Link 
              to='/services'
              className='text-white text-[1.1rem]'
            >services</Link>
          </li>
          <li>
            <Button 
              style={{
                borderRadius: '50%',
                width: '2.5rem',
                height: '2.5rem',
              }}
              styles="flex items-center justify-center relative"
              aria-label="shopping cart"
              linkTo={userInfo ? '/cart' : '/auth'}
            >
              <i className="fas fa-shopping-cart"></i>
              <span className="sr-only">shopping cart</span>
              <h2 className='absolute -bottom-1 shadow-sm shadow-cyan-500 right-1 bg-black-gradient-2 px-[1px] py-[1px] h-5 w-5 flex-center text-white rounded-full text-xs'>{userInfo ? cartItems?.length : 0}</h2>
            </Button>
          </li>
          {!userInfo && <li>
            <Link 
              to='/auth'
              className='text-white text-[1.1rem]'
            >Login</Link>
          </li>}
          <li>
            <SearchButton 
              onClick={() => setShowSearchBar(prev => !prev)}
            />
          </li>
        </ul>
      </button> 
      {/* end of mobile nav */}

      {/* search input field */}
      <SearchInput 
        styles={`fixed top-[8rem] right-1/2 -translate-x-1/2 ${showSearchBar ? 'search-active' : 'search-inactive'}`}
        closeSearchBar={() => setShowSearchBar(false)}
      />
      {/* end of search input field */}
    </nav>
  )
} 

export default Navbar