import React from 'react'

const TestimonialCard = ({msg, img, name, subText, ...otherProps}) => {
  return (
    <div {...otherProps}>
      <div tabIndex="0" className='text-white border-gradient py-4 sm:py-6 px-2 xs:px-4 w-full sm:w-72 md:w-96 rounded-[20px] flex flex-col gap-3 sm:gap-5 justify-between feedback-card min-h-[200px] sm:min-h-[280px]'>
        <i className="fa-solid fa-quote-left text-2xl xs:text-3xl sm:text-4xl md:text-6xl text-gradient"></i>
        <p className='italic font-thin text-xs xs:text-sm sm:text-base'>{msg}</p>
        <div className="w-full flex items-center gap-2">
          <figure className='h-[40px] w-[40px] xs:w-[50px] xs:h-[50px] md:w-[60px] md:h-[60px] rounded-full flex-shrink-0'>
            <img src={img} alt="person" className='rounded-full w-full h-full' loading='lazy'  />
          </figure>
          <div className="">
            <h3 className='font-semibold resp-sub-heading'>{name}</h3>
            <p className='text-[14px] font-thin tracking-tight text-gray-400 resp-text'>{subText}</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TestimonialCard