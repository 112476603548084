import React from 'react';

const PaymentModal = ({ isOpen, onClose, onConfirm }) => {
  if (!isOpen) return null;
 
  return (

    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-[10000000] ">
        <div className="bg-black-gradient resp-p-sm rounded-md w-[90%] sm:w-[400px] flex flex-col gap-4 items-center shadow shadow-[rgba(255,255,255,.2)]" role='alert'>
            <h2 className='resp-sub-heading text-cyan-100 animate__animated animate__slideInDown'>Choose Payment Method</h2>
            <button 
                onClick={() => onConfirm('Pay on Delivery')} 
                className="text-primary bg-blue-gradient rounded py-2 px-4 btn-glow animate__animated animate__slideInDown resp-text"
                style={{animationDelay: '.2s'}}
            ><i class="fa-solid fa-sack-dollar mr-2"></i> Pay on Delivery</button>
            <button 
                onClick={() => onConfirm('Pay with Card')} 
                className="text-primary bg-blue-gradient rounded py-2 px-4 btn-glow animate__animated animate__slideInDown resp-text"
                style={{animationDelay: '.4s'}}
            ><i className="fas fa-credit-card mr-2" aria-hidden="true"></i> Pay with Card</button>
            <button 
                onClick={() => onConfirm('Pay with Mobile Money')} 
                className="text-primary bg-blue-gradient rounded py-2 px-4 btn-glow animate__animated animate__slideInDown resp-text"
                style={{animationDelay: '.6s'}}
            ><i class="fa-solid fa-money-bill-wave mr-2"></i> Pay with Mobile Money</button>
            <button 
                onClick={onClose} 
                className="text-white bg-gray-600 rounded py-2 px-4 animate__animated animate__slideInDown"
                style={{animationDelay: '.8s'}}
            >Cancel</button>
        </div>
    </div>

  );
};

export default PaymentModal;
