import React from 'react'

const Modal = ({ isVisible, onClose, onConfirm, heading = "Modal heading" }) => {
    if (!isVisible) return null;
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-[10000]">
      <div className="bg-black bg-opacity-50 absolute inset-0" onClick={onClose}></div>
      <div className="bg-black-gradient p-6 rounded-md w-[90%] sm:w-[400px] flex flex-col gap-4 items-center shadow shadow-[rgba(255,255,255,.2)] z-[10001]"  role='alert'>
        <h2 className="text-white text-lg mb-4">{heading}</h2>
        <div className="flex items-center justify-between w-full">
            <button 
                className="bg-red-500 text-white py-2 px-4 rounded" 
                onClick={onConfirm}
            >Yes</button>
            <button 
            className="bg-blue-gradient text-slate-800 py-2 px-4 rounded mr-2" 
            onClick={onClose}
            >No</button>
        </div>
      </div>
    </div>
  )
}

export default Modal