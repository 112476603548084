import React, { useState, useEffect } from 'react'
import * as Yup from 'yup'
import { useNavigate } from 'react-router-dom'

import { SubmitButton, FormField, Form, ErrorMessage } from './form'
import { useAuth } from '../context/AuthProvider' 
import CircleLoading from './CircleLoading'

const validationSchema = Yup.object().shape({
    username: Yup.string().required("Please enter your username").min(3).label("Username"),
    email: Yup.string().required("Please enter email").email().label("Email"),
    password: Yup.string().required("Please insert password").min(8).label("Password").matches(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/, "Must contain at least one uppercase, one lowercase, one number and one special character"),
    re_password: Yup.string()
        .oneOf([Yup.ref('password'), null], "Passwords must match")
        .required("Please confirm your password")
})

const SignUpForm = () => {
    const [passwordVisible, setPasswordVisible] = useState(false)
    const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false)

    const { register, error, setError, registerLoading, isFirstTime } = useAuth()
    const navigate = useNavigate()

    useEffect(() => {
        setError('')
        return () => setError('')  // Cleanup function
    }, [setError])

    const registerError = error?.register ? 
        (Array.isArray(error.register.username) ? error.register.username[0] : error.register.username) 
        : error?.network 

    const handleSubmit = async ({ username, email, password, re_password }) => {
        const result = await register(username.trim(), email.trim().toLowerCase(), password, re_password)
        if (result.success) {
            navigate('/verification')
        }
    }

    if(isFirstTime) {
        navigate('/verification')
    }

    return (
        <div className="w-full pb-6 px-1 xs:px-2 sm:px-5 flex-center flex-col rounded-lg">
            <div className="h-10 text-center text-2xl w-full py-1">
                <h2 
                    className='text-gradient animate__animated animate__bounceInDown'
                    style={{
                        animationDelay: '0.05s'
                    }}
                >Sign Up Form</h2>
            </div>
            <div className="w-full ">
                <ErrorMessage error={registerError} visible={!!registerError} />
                <CircleLoading visible={registerLoading} />
                <Form
                    initialValues={{ username: '', email: '', password: '', re_password: '' }}
                    onSubmit={handleSubmit}
                    validationSchema={validationSchema}
                >
                    <div 
                        className="w-full my-8 animate__animated animate__bounceInDown"
                        style={{
                            animationDelay: '0.3s'
                        }}
                    >
                        <FormField
                            icon="user"
                            placeholder="johndoe"
                            label="Username"
                            name="username"
                        />
                    </div>
                    <div 
                        className="w-full my-8 animate__animated animate__bounceInDown"
                        style={{
                            animationDelay: '0.5s'
                        }}
                    >
                        <FormField
                            icon="envelope"
                            placeholder="john@example.com"
                            label="Email"
                            name="email"
                        />
                    </div>
                    <div 
                        className="w-full my-8 animate__animated animate__bounceInDown"
                        style={{
                            animationDelay: '0.7s'
                        }}
                    >
                        <FormField
                            icon={passwordVisible ? "eye-slash" : "eye"}
                            placeholder="Password"
                            label="Password"
                            type={passwordVisible ? "text" : "password"}
                            name="password"
                            onIconClick={() => setPasswordVisible(prev => !prev)}
                            ariaLabel={passwordVisible ? "Hide password" : "Show password"}
                        />
                    </div>
                    <div 
                        className="w-full mt-8 mb-5 animate__animated animate__bounceInDown"
                        style={{
                            animationDelay: '0.9s'
                        }}
                    >
                        <FormField
                            icon={confirmPasswordVisible ? "eye-slash" : "eye"}
                            placeholder="Confirm Password"
                            label="Confirm Password"
                            type={confirmPasswordVisible ? "text" : "password"}
                            name="re_password"
                            onIconClick={() => setConfirmPasswordVisible(prev => !prev)}
                            ariaLabel={confirmPasswordVisible ? "Hide confirm password" : "Show confirm password"}
                        />
                    </div>
                    <div 
                        className="w-full py-1 md:py-4 flex-center animate__animated animate__bounceInDown"
                        style={{
                            animationDelay: '1.1s'
                        }}
                    >
                        <SubmitButton title="Sign Up"/>
                    </div>
                </Form>
            </div>
        </div>
    )
}

export default SignUpForm